var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{attrs:{"to":{
    name: "work.details",
    params: {
      pArtist: _vm.value.artist.replace(/ /g, '-'),
      pTitle: _vm.value.title.replace(/ /g, '-'),
      id: _vm.artworkId,
    },
  }}},[_c('div',{staticClass:"collector-work"},[_c('img',{attrs:{"src":_vm.value.src}}),_c('div',{staticClass:"collector-work__header"},[_c('div',[_vm._v(_vm._s(_vm.value.title)+" ("+_vm._s(_vm.value.year)+")")]),_c('div',[_vm._v(_vm._s(_vm.value.artist))])]),_c('div',{staticClass:"collector-work__data"},[_c('div',[_c('div',[_vm._v("Owned Frames")]),_c('div',[_vm._v(_vm._s(_vm.value.ownedFrames.toLocaleString()))])]),_c('div',[_c('div',[_vm._v("Owned Percentage")]),_c('div',[_vm._v(_vm._s(_vm.value.percentageOwned)+"%")])]),_c('div',[_c('div',[_vm._v("Work Frames")]),_c('div',[_vm._v(_vm._s(_vm.value.totalFrames.toLocaleString()))])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }