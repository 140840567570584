<template>
  <div class="collector-page">
    <div class="collector-page__wrap">
      <div class="neo-card">
        <NeoAvatar
          v-if="src"
          :photoURL="src"
          :username="username"
          verified
          :big="true"
        />
        <ul class="collector-page__works">
          <li v-for="item in collection" :key="item.id">
            <CollectorWork :value="item" :artworkId="item.id" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import NeoAvatar from "@/components/Molecules/NeoAvatar";
import CollectorWork from "@/components/Organisms/CollectorWork";

// import ArtworkDS from "@/services/ArtworkDS";
import UsersDS from "@/services/UsersDS";

export default {
  name: "vCollector",
  props: {
    id: String,
  },
  components: {
    NeoAvatar,
    CollectorWork,
  },
  data: () => ({
    avatar: {
      username: "",
      src: "",
    },
    username: '',
    src: '',
    verified: false,
    collection: [],
  }),

  async mounted() {
    // Get collector info
    const rtdbCollector = await UsersDS.getCollectorsArtwork(this.id);
    if (rtdbCollector.exists()) {
      const userInfo = rtdbCollector.val();
      const usernameSnap = await UsersDS.getUsername(this.id);
      const collectorInfo = usernameSnap.data();
      this.username = collectorInfo.username;
      this.src = `${process.env.VUE_APP_IMAGES_URL}avatars/${this.id}.jpg`;
      for (let artworkId in userInfo) {
        let item = {};
        const snap = userInfo[artworkId];
        item.id = artworkId;
        item.title = snap.title;
        item.artist = snap.artist;
        item.year = snap.year;
        item.src = `${process.env.VUE_APP_IMAGES_URL}artwork/${artworkId}/${artworkId}_1.jpg`;
        item.ownedFrames = snap.quantity;
        item.totalFrames = snap.totalFrames;
        item.percentageOwned =
          Math.round(((100 * item.ownedFrames) / item.totalFrames) * 1000) / 1000;
        item.flex = 3;
        this.collection.push(item);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.collector-page {
  padding: 16px;
  background: var(--color-grey-dark);
  height: 100%;
  &__wrap {
    position: relative;
    max-width: 800px;
    padding-top: 50px;
    margin: 50px auto 0;

    @media only screen and (min-width: 1024px) {
      margin: 100px auto 0;
      padding-top: 100px;
    }

    &:after {
      position: absolute;
      top: -50px;
      left: 50%;
      transform: translate(-50%, -0);
      content: "";
      width: 125px;
      height: 125px;
      border-radius: 50%;
      margin: auto;
      background: var(--color-white);

      @media only screen and (min-width: 1024px) {
        top: -100px;
        width: 250px;
        height: 250px;
      }
    }
    .neo-avatar {
      position: absolute;
      top: -40px;
      width: 100%;
      @media only screen and (min-width: 1024px) {
        top: -80px;
      }

      left: 50%;
      transform: translate(-50%, -0);
      z-index: 2;
    }
  }
  &__works {
    margin-top: 48px;
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(auto-fit, minmax(240px, 240px));
    justify-content: center;
    li {
      height: 368px;
    }
  }
}
</style>
