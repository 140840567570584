<template>
  <router-link
    :to="{
      name: `work.details`,
      params: {
        pArtist: value.artist.replace(/ /g, '-'),
        pTitle: value.title.replace(/ /g, '-'),
        id: artworkId,
      },
    }"
  >
    <div class="collector-work">
      <img :src="value.src" />
      <div class="collector-work__header">
        <div>{{ value.title }} ({{ value.year }})</div>
        <div>{{ value.artist }}</div>
      </div>

      <div class="collector-work__data">
        <div>
          <div>Owned Frames</div>
          <div>{{ value.ownedFrames.toLocaleString() }}</div>
        </div>
        <div>
          <div>Owned Percentage</div>
          <div>{{ value.percentageOwned }}%</div>
        </div>
        <div>
          <div>Work Frames</div>
          <div>{{ value.totalFrames.toLocaleString() }}</div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "CollectorWork",
  props: {
    value: Object,
    artworkId: String,
  },
};
</script>

<style lang="scss" scoped>
.collector-work {
  $this: &;
  height: 100%;
  text-align: center;
  padding: 8px;
  background: var(--color-grey);
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;

  img {
    max-height: 80%;
  }

  &__header {
    position: absolute;
    top: 4px;
    left: 4px;
    width: calc(100% - 8px);
    border-radius: 4px 0 0 0;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 4px;
    text-align: left;
    font-weight: 600;

    @media only screen and (min-width: 1024px) {
      visibility: hidden;
      transition: transform 200ms linear;
      transform: scale(0);
      transform-origin: top left;
    }

    #{$this}:hover & {
      visibility: visible;
      transform: scale(1);
    }

    > div:last-child {
      font-weight: 400;
    }
  }
  &__data {
    margin-top: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    font-size: var(--font-size-super-small);
    line-height: 1;
    > div {
      padding: 8px 8px 0;
      ~ div {
        border-left: 1px solid var(--color-white);
      }
    }
    div > div:first-child {
      font-weight: 300;
      color: black;
    }
    div > :last-child {
      font-weight: 600;
      font-size: 1.5em;
    }
  }
}
</style>
